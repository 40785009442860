import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Parser from "html-react-parser";
import { Helmet } from "react-helmet";


function BlogProfile() {
  const [blogContent, setBlogContant] = useState([]);
  const {blogid} = useParams();
  useEffect(() => {
    Axios.get(`https://server.webwisdom.work/
api/blogprofile/${blogid}`).then((res) => {
          console.log(res.data)
          setBlogContant(res.data);
        })
        // eslint-disable-next-line
  }, []);
  
  return (
    <main className="blogProfile">
      {blogContent.map((value) => {
        const title = (value.title).replaceAll('-', ' ');
        const category = value.category;
        return(
          <>
          <Helmet>
          <title>{title}</title>
        <meta name="description" content="Deep learning tool and tips to catelize the work with weekly Blogs." />
        <meta name="keywords" content={category} />
        <meta name="author" content="Prabhjot Kumar" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content={value.image} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`https://webwisdom.work/${blogid}`} />
        <meta property="og:image" content={`https://webwisdom.work/${value.image}`} />
        <meta property="og:description" content="WebWisdom.work blog helps you learn and streamline your work with tools and tutorials." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content="WebWisdom.work blog helps you learn and streamline your work with tools and tutorials." />
        <meta name="twitter:image" content={`https://webwisdom.work/${value.image}`} />
          </Helmet>
          <h2 className="blogTitle" key={value.blogid}>{title}</h2>
          <p className="blogCategory">{category}</p>
          <img src={value.image} alt={title} className="blogImage" />
          <section className="blogContent">
          {Parser(value.code)}
          </section>
          </>
        )
      })}
    </main>
  )
}

export default BlogProfile;