// Bundles
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Helmet } from "react-helmet";

//Sections
import Request from "./Request";

//Assets
import logo from "./Image/logo.png";

function Home() {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    Axios.get("https://server.webwisdom.work/api/getcategory").then((res) => {
      setCategoryList(res.data);
    });
  }, []);

  return (
    <main>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="General sections for website of web wisdom work. Explore here for more!"
        />
        <meta
          name="keywords"
          content="web wisdom work, webwisdom.work, web tools, web apps"
        />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta
          property="og:image"
          content="https://webwisdom.work/default.jpg"
        />
        <meta
          property="og:description"
          content="General sections for website of web wisdom work. Explore here for more!"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta
          name="twitter:description"
          content="General sections for website of web wisdom work. Explore here for more!"
        />
        <meta
          name="twitter:image"
          content="https://webwisdom.work/default.jpg"
        />
      </Helmet>
      <section className="Intro">
      <div>
          <img src={logo} alt="Logo made by Owner" />
        </div>
        <div>
          <h2>Earn The Wisdom of Web Apps and Internet</h2>
          <p>
            WebWisdom.WORK is des-dev web tool list and learning resource that can help you to plan 🤔, structure 💠, get assets 📼, make assets, help in design, front-end des-dev 🗺 , back-end dev,
            server structure, in deployment and many more.
          </p>
          <Link to="../sign" className="btn2">
            Sign Up
          </Link>
          <Link to="../tutorial" className="btn1">
            Tools
          </Link>
        </div>
        
      </section>
      <section className="homeCategory">
        <h2> Find tool in category</h2>
        <div className="categoryGrid">
        {categoryList.map((value) => {
          return(
            <Link to={`../categoryname/${value.name}`} className="categoryCard" key={value.categoryid}>
                <img src={value.categoryicon} alt="tester category" />
                <h3>{value.name}</h3>
              </Link>
          )
        })}
        </div>
      </section>
      <Request />
    </main>
  );
}

export default Home;
