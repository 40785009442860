import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <Link to="../../">
        <h1 className="logo">
          WebWisdom<span>.Work</span>
        </h1>
      </Link>
      <nav>
        <Link to="../home" title="HOME">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve">
            <g>
              <path
                d="M253.8,0.5c4.8,0.3,9.4,1.6,13.6,4c80.1,80.1,160.2,160.2,240.2,240.2c7,16.2,1.9,26.5-15.3,30.7
		c-4-0.6-7.8-2-11.4-4c-74.8-75-149.8-149.8-224.9-224.3C180.9,121.6,106,196.4,31.2,271.4c-16.2,7-26.4,1.9-30.7-15.3
		c0.7-4,2-7.8,4-11.4C84.6,164.6,164.6,84.6,244.7,4.5C247.7,2.8,250.8,1.5,253.8,0.5z"
              />
            </g>
            <g>
              <path
                d="M67.5,292.4c11.2-2.4,19.3,1.3,24.4,11.4c0.4,40.1,0.8,80.3,1.1,120.4c4.5,29.1,21.4,45.6,50.5,49.4
		c75,0.8,149.9,0.8,224.9,0c29.2-3.8,46-20.3,50.5-49.4c0.4-40.1,0.8-80.3,1.1-120.4c5.1-10.2,13.2-13.8,24.4-10.8
		c5.1,2.1,8.7,5.7,10.8,10.8c0.8,43.5,0.8,87.1,0,130.6c-8.5,41.8-33.7,67-75.5,75.5c-4.6,0.3-9.1,0.8-13.6,1.7
		c-73.5-1.5-146.9-1.5-220.4,0c-45.4-3.6-74.8-27.1-88-70.4c-0.4-45.8-0.8-91.6-1.1-137.4C59,298.7,62.6,294.9,67.5,292.4z"
              />
            </g>
          </svg>
        </Link>
        <Link to="../tab" title="TOOL">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve">
            <path
              d="M499.5-0.5c-126,0-252,0-378,0c-6.8,2.7-11,7.7-12.5,15c-0.5,31.3-0.7,62.7-0.5,94c-31.3-0.2-62.7,0-94,0.5
	c-7.3,1.5-12.3,5.7-15,12.5c0,126,0,252,0,378c2.3,5.7,6.3,9.7,12,12c126,0,252,0,378,0c6.8-2.7,11-7.7,12.5-15
	c0.5-31.3,0.7-62.7,0.5-94c31.3,0.2,62.7,0,94-0.5c7.3-1.5,12.3-5.7,15-12.5c0-126,0-252,0-378C509.2,5.8,505.2,1.8,499.5-0.5z
	 M364.5,474.5c-109.3,0-218.7,0-328,0c0-109.3,0-218.7,0-328c109.3,0,218.7,0,328,0C364.5,255.8,364.5,365.2,364.5,474.5z
	 M474.5,364.5c-24,0-48,0-72,0c0.2-80,0-160-0.5-240c-1.8-8.5-7-13.7-15.5-15.5c-80-0.5-160-0.7-240-0.5c0-24,0-48,0-72
	c109.3,0,218.7,0,328,0C474.5,145.8,474.5,255.2,474.5,364.5z"
            />
          </svg>
        </Link>
        <Link to="../blog" title="BLOG">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve">
            <g>
              <path
                d="M509.4,224c-6.3-24.2-21.6-39.5-45.8-45.8c-17.1-0.6-34.2-0.8-51.3-0.6c0.2-31.4,0-62.7-0.6-94.1
		c-9.2-45-36.3-72.1-81.3-81.3C248,1.5,165.7,1.3,83.4,1.5C41.5,10.6,14.8,35.8,3.3,77.3c-1.4,45-2,90-1.8,135.1
		c0.2,43,0.4,86,0.6,129c2.2,5.5,6.1,9.4,11.6,11.6c12.1,3.2,20.8-0.6,26.3-11.6c0.4-80.7,0.8-161.3,1.2-242
		c0.3-8,1.9-15.8,4.9-23.2c11-21.9,28.7-33.5,53.2-34.8c75.8-1.2,151.6-0.8,227.4,1.2c28.4,8.4,43.7,27.4,45.8,56.8
		c0.4,35,0.8,70.1,1.2,105.1c2.2,5.5,6.1,9.4,11.6,11.6c24.1,0.1,48.1,0.9,72.1,2.4c5.5,2.2,9.4,6.1,11.6,11.6
		c2,60.7,2.4,121.4,1.2,182.1c-2.2,29.5-17.5,48.4-45.8,56.8c-108.4,2-216.7,2.4-325.1,1.2c-38-5-57.8-26.8-59.3-65.4
		c-2.2-5.5-6.1-9.4-11.6-11.6c-12.1-3.2-20.8,0.6-26.3,11.6c-0.8,7.7-0.8,15.5,0,23.2c9.2,45,36.3,72.1,81.3,81.3
		c114.9,0.8,229.8,0.8,344.7,0c45-9.2,72.1-36.3,81.3-81.3C510.2,360.1,510.2,292,509.4,224z"
              />
              <path
                d="M156.7,216c33.4,0.8,66.8,0.8,100.2,0c31.1-10.4,43.1-31.8,36.1-64.2c-6.2-17.2-18.3-28-36.1-32.4
		c-33.4-0.6-66.8-0.8-100.2-0.6c-28.5,9.7-40.9,29.6-37.3,59.9C124.6,198.5,137,210.9,156.7,216z"
              />
              <path
                d="M151.9,390.8c67.6,2,135.2,2.4,202.9,1.2c17.8-4.4,29.8-15.1,36.1-32.4c7-29.8-3.4-50.8-31.2-63
		c-67.6-1.8-135.2-2.4-202.9-1.8c-17.6,4.7-29.6,15.7-36.1,33C113.7,357.7,124.1,378.7,151.9,390.8z"
              />
            </g>
          </svg>
        </Link>
        <Link to="../about" title="CONTACT">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512">
            <g>
              <path d="M 269.5,-0.5 C 278.5,-0.5 287.5,-0.5 296.5,-0.5C 384.456,10.9657 447.622,56.6324 486,136.5C 499.461,167.805 505.461,200.472 504,234.5C 497.613,246.783 488.113,249.95 475.5,244C 472.567,241.736 470.4,238.902 469,235.5C 466.429,149.453 426.596,87.9526 349.5,51C 324.789,41.1589 299.122,36.1589 272.5,36C 262.719,33.7165 257.385,27.5498 256.5,17.5C 257.067,8.62341 261.4,2.62341 269.5,-0.5 Z" />
            </g>
            <g>
              <path d="M 365.5,511.5 C 357.167,511.5 348.833,511.5 340.5,511.5C 330.36,509.628 320.36,506.794 310.5,503C 165.635,442.801 65.4685,339.301 10,192.5C -1.06314,138.791 18.7702,101.624 69.5,81C 89.317,73.3185 107.984,75.3185 125.5,87C 154.866,113.368 177.7,144.535 194,180.5C 201.93,210.815 191.763,232.981 163.5,247C 152.009,249.507 144.176,245.34 140,234.5C 138.734,228.405 139.734,222.738 143,217.5C 148,213.167 153,208.833 158,204.5C 161.034,199.907 161.034,195.241 158,190.5C 143.613,163.237 125.446,138.737 103.5,117C 98.9768,113.827 93.9768,112.827 88.5,114C 70.9551,118.888 57.4551,129.055 48,144.5C 43.4044,155.502 42.4044,166.836 45,178.5C 96.4615,316.296 190.295,413.13 326.5,469C 361.029,482.055 385.862,471.888 401,438.5C 404.531,431.73 405.197,424.73 403,417.5C 392.292,405.403 380.459,394.57 367.5,385C 353.682,374.526 338.848,365.693 323,358.5C 319.678,357.483 316.512,357.983 313.5,360C 310.067,363.042 307.234,366.542 305,370.5C 297.604,378.876 288.771,380.71 278.5,376C 269.691,368.114 268.191,358.947 274,348.5C 291.498,321.585 314.998,314.418 344.5,327C 375.032,342.194 402.198,362.028 426,386.5C 443.807,407.079 446.807,429.746 435,454.5C 421.506,485.511 398.339,504.511 365.5,511.5 Z" />
            </g>
            <g>
              <path d="M 270.5,104.5 C 326.354,104.992 366.188,130.326 390,180.5C 396.811,197.178 399.811,214.511 399,232.5C 394.904,244.464 386.737,248.964 374.5,246C 368.667,244.167 364.833,240.333 363,234.5C 359.303,176.47 328.47,145.303 270.5,141C 258.793,136.753 254.626,128.586 258,116.5C 260.632,110.791 264.799,106.791 270.5,104.5 Z" />
            </g>
          </svg>
        </Link>
        <Link to="../dashboard" title="SIGN|IN|UP">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" space="preserve" viewBox="0 0 512 512">
            <path
              d="M492,158.5C449.9,66,378.4,13.3,277.5,0.5c-15.3,0-30.7,0-46,0c-28.3,3.1-55.6,10.2-82,21.5
	C61.2,65.3,10.9,135.8-1.5,233.5c0,15.3,0,30.7,0,46c3.7,36,14.2,70,31.5,102c45.4,76.9,112.6,120.5,201.5,131c15.3,0,30.7,0,46,0
	c36-3.7,70-14.2,102-31.5c76.4-45.7,120.1-112.9,131-201.5c0-15.3,0-30.7,0-46C507.7,207.8,501.5,182.8,492,158.5z M190.5,474
	c-22.2-6.5-42.9-16.1-62-29c-1.1-0.7-1.7-1.7-2-3c15.2-59.3,52.8-94.2,113-104.5c56.1-3,98.6,19.3,127.5,67
	c6.8,12.3,11.1,25.3,13,39C321.5,482.2,258.3,492.3,190.5,474z M179,195.5c12.7-29.5,34.8-46.9,66.5-52c37.7-1.8,65.2,13.9,82.5,47
	c14.5,37.9,6.7,70.4-23.5,97.5c-33.3,23.3-66.7,23.3-100,0C175.8,262.8,167.3,231.9,179,195.5z M464,339.5
	c-12.7,31.7-31.4,59.4-56,83c-15.5-47.7-46-82-91.5-103c39.1-29.4,54.6-68.4,46.5-117c-13.7-50.7-46.5-79.9-98.5-87.5
	c-53.5-2.4-91.6,20.5-114.5,68.5c-15.9,43.8-8.9,83.5,21,119c6.4,5.9,12.9,11.5,19.5,17c-45.5,21-76,55.3-91.5,103
	c-61.4-61.4-82.7-134.8-64-220C59.8,119,114,64.8,197.5,40c18.3-4.2,36.6-6.7,55-7.5c73,1.9,132.5,30.9,178.5,87
	C480.5,186.9,491.5,260.2,464,339.5z"
            />
          </svg>
        </Link>
      </nav>
    </header>
  );
}

export default Header;
