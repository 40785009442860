import React, {useState} from 'react';
import Axios from "axios";

function Request() {
  const [requestToolName, setRequestToolName] = useState("");
  const [requestToolLink, setRequestToolLink] = useState("");
  const [requestYourName, setRequestYourName] = useState("");
  const [requestYourEmail, setRequestYourEmail] = useState("");

  const requestTool = () => {
    Axios.post("https://server.webwisdom.work/requestform", {
      requestToolName:requestToolName,
      requestToolLink:requestToolLink,
      requestYourName:requestYourName,
      requestYourEmail:requestYourEmail
    }).then(() => {
      alert("Successfully Inserted");
    })
  }
  return (
    <section className="requestTool">
        <h2>🍺Request Your 💕Favorite Tool🪓</h2>
          <div className="form">
            <input type="text" name="name" placeholder="App Name 🛠"
            onChange={(e) => {
              setRequestToolName(e.target.value);
          }}
            />
            <input type="url" placeholder="App Link 🔗"
            onChange={(e) => {
              setRequestToolLink(e.target.value);
          }}
            />
            <input type="text" placeholder="Your Name 🧞"
            onChange={(e) => {
              setRequestYourName(e.target.value);
          }}
            />
            <input type="email" placeholder="Your email 📭"
            onChange={(e) => {
              setRequestYourEmail(e.target.value);
          }}
            />
            <p> 🛑 We do not store any of your persoanl data on our site. Email and name is optional and to notify you, if your Tool is added to the site or not for given reason. </p>
          <p> 🛑 By submiting this form you agree to acknowlegde and accept our all privacy, disclaimers , terms & conditions. </p>
            <button className="btn1" type="submit" onClick={requestTool} >Request🍺</button>
            </div>
    </section>
  )
}

export default Request