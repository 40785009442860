import React from 'react'
// import { Link } from 'react-router-dom';
import Request from "../Request";

function FAQ() {
  return (
    <main className="QA">
    <img src="https://i.ibb.co/mykX5Rx/FAQ.png" className="faqheader" alt="FAQ" />
    <section>
    <h3 className="Question">Introduction</h3>
    <p className="Answer">Welcome to Q and A of webwisdom.work. Learn🎓 your favorite tool, 📌Pin the tool that you like & 🔗Open tool link.  WebWisdom.WORK is des-dev web tool list and learning resource that can help you to plan 🤔, structure 💠, get assets 📼, make assets, help in design, front-end des-dev 🗺 , back-end dev,
            server structure, in deployment and many more.  </p>
    </section>
    <section>
    <h3 className='Question'>How to Make account in webwisdom.work?</h3>
    <p className='Answer'> 1. Go to sign in/up page and then write your email in the inbox. Submit the form. Verification email will be sent to your email check your email and click to verify on link in email. </p>
    <p className='Answer'> 2. You will automatic redirect to your dashboard. No password is required to log in and register account. </p>
    </section>
    <section>
    <h3 className='Question'>How to sign account in webwisdom.work?</h3>
    <p className='Answer'> 1. Go to sign in/up page and then write your email in the inbox. Submit the form. Verification email will be sent to your email check your email and click to verify on link in email. </p>
    <p className='Answer'> 2. You will automatic redirect to your dashboard. No password is required to log in. </p>
    </section>
    <Request />
    </main>
  )
}

export default FAQ;