import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Blog() {
  const [searchTerm, setSearchTerm] = useState("");
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    Axios.get(`https://server.webwisdom.work/
api/getblog`).then((res) => {
  setBlogList(res.data);
});
  }, [])

  const searchQuery = (e) => {
    if (searchTerm !== "" || searchTerm !== " ") {
      Axios.get(`https://server.webwisdom.work/
api/search/${searchTerm}`).then((res) => {
        setBlogList(res.data);
      });
    }
    e.preventDefault();
    };
    const searchReset = (e) => {
      console.log("Reset")
    Axios.get(`https://server.webwisdom.work/
api/gettool`).then((res) => {
      setBlogList(res.data);
    });
    e.preventDefault();
    };

  return (
    <main className="Blog">

      <Helmet>
        <title>Web Wisdom Work Blog</title>
        <meta name="description" content="Deep learning tool and tips to catelize the work with weekly Blogs." />
        <meta name="keywords" content="Tutorial, web wisdom work blog, blog, free tutorial, buy tutorial" />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta property="og:image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:description" content="WebWisdom.work blog helps you learn and streamline your work with tools and tutorials." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta name="twitter:description" content="WebWisdom.work blog helps you learn and streamline your work with tools and tutorials." />
        <meta name="twitter:image" content="https://webwisdom.work/default.jpg" />
      </Helmet>

      <h2>Explore Blogs 🕵️‍♀️</h2>
      <form action="../blog">
        <input type="text" placeholder="search" onChange={(e) => {
            setSearchTerm(e.target.value);
          }} />
        <button type="submit" onClick={searchQuery} >🔍</button>
        <button type="reset" onClick={searchReset}>⛔</button>
      </form>

      <section className="blogGrid">
          {blogList.map((value) => {
            return(
              <Link to={`../../blogprofile/${value.title}`} title={value.name} className="blogCard" key={value.blogid}>
              <img src={value.image} alt={value.name} />
              <p className="blogCategory">{value.category}</p>
              <h3>{(value.title).replaceAll('-', ' ')}</h3>
              <p className="blue">📖Read more...</p>
              </Link>
            )
          })}
      </section>

    </main>
  )
}

export default Blog