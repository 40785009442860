import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { isSignInContext, tokenContext, signEmailContext } from "./Context";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function CategoryProfile() {
  const { categoryName } = useParams();
  const [categoryHead, setCategoryHead] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { isSignIn } = useContext(isSignInContext);
  const { signEmail } = useContext(signEmailContext);
  const { token } = useContext(tokenContext);

  useEffect(() => {
    Axios.get(`https://server.webwisdom.work/
categoryname/${categoryName}`).then((res) => {
      setCategoryHead(res.data);
      console.log(res.data);
    });
    Axios.get(`https://server.webwisdom.work/
categoryprofile/${categoryName}`).then((res) => {
      setCategoryList(res.data);
    });
    // eslint-disable-next-line
  }, []);

  const searchQuery = (e) => {
    if (searchTerm !== "" || searchTerm !== " ") {
      Axios.get(`https://server.webwisdom.work/
api/search/${searchTerm}`).then((res) => {
        setCategoryList(res.data);
      });
    }
    e.preventDefault();
  };
  const searchReset = (e) => {
    Axios.get(`https://server.webwisdom.work/
api/gettool`).then((res) => {
      setCategoryList(res.data);
    });
    e.preventDefault();
  };

  const addTabMark = (tabCode) => {
    if (isSignIn) {
      Axios.post(`https://server.webwisdom.work/
api/addtab`, {
        signEmail: signEmail,
        tabCode: tabCode,
        token: token,
      }).then((err, res) => {
        console.log(res);
      });
    } else {
      <Navigate to="../sign" />;
    }
  };

  return (
    <main>
      {categoryHead.map((value) => {
        return(
          <section key={value.categoryid} className="categoryHead">
                  <Helmet>
        <title>{value.name}</title>
        <meta
          name="description"
          content="Learn about Tools that fall into this category"
        />
        <meta
          name="keywords"
          content="category of web wisdom work, category, tool by category, web wisdom work by category, webwisdom category"
        />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta
          property="og:image"
          content="https://webwisdom.work/default.jpg"
        />
        <meta
          property="og:description"
          content="Learn about this website and creator in detail. About author of the website. Who made this website"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta
          name="twitter:description"
          content="Learn about this website and creator in detail. About author of the website. Who made this website"
        />
        <meta
          name="twitter:image"
          content="https://webwisdom.work/default.jpg"
        />
      </Helmet>
          <img src={value.categoryicon} alt={value.name} />
          <h2>{value.name}</h2>
        </section>
        )
      })}
      <form action="../tutorial" className="searchBar">
            <input
              type="text"
              placeholder="search"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <button type="submit" onClick={searchQuery}>
              🔍
            </button>
            <button type="reset" onClick={searchReset}>
              ⛔
            </button>
          </form>

<section className="tabGrid">
{categoryList.map((value) => {
        return (
          <div className="tabCard" key={value.toolid}>
            <iframe src={`https://www.youtube.com/embed/${value.videolink}`} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
            <div className="tabPlat">
              <div>
                <img src={value.imagelink} alt={value.name} />
                <h3>{value.name}</h3>
              </div>
              <div>
                <a className="weblink" href={value.tutoriallink} title={`Learn ${value.name}`}>
                  🎓
                </a>
                <a href={value.weblink} target="_blank" rel="noopener noreferrer" className="weblink">
                  🔗
                </a>
                <button className="weblink" onClick={addTabMark(value.toolid)}> 📌 </button>
              </div>
            </div>
          </div>
        );
      })}
</section>
      
    </main>
  );
}

export default CategoryProfile;
