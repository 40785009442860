import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { isSignInContext, tempPassContext, tokenContext, signEmailContext } from "./Context";
import { Navigate } from "react-router-dom";
// import { Link } from "react-router-dom";

function Dashboard() {
  // UNIVERSAL STATES
  const { isSignIn, setIsSignIn } = useContext(isSignInContext);
  const { signEmail, setSignEmail } = useContext(signEmailContext);
  const { token, setToken } = useContext(tokenContext);
  const { tempPass, setTempPass } = useContext(tempPassContext);

  const [toolList, setToolList] = useState([]);
  useEffect(() => {
    Axios.get("https://server.webwisdom.work/issignin").then((res) => {
      setIsSignIn(res.data.isSignIn);
      setSignEmail(res.data.mail);
      setToken(res.data.token);
      setTempPass(res.data.tempPassword);
      localStorage.setItem("Email", signEmail);
      localStorage.setItem("Temporary Password", tempPass);
      localStorage.setItem("Email", token);
    });
    if (isSignIn) {
      Axios.get(`https://server.webwisdom.work/
api/usertools/${signEmail}`).then((res) => {
      setToolList(res.data);
    })
    }
    
// eslint-disable-next-line
  }, [])
  const removeTabMark = (logid) => {
    console.log(logid);
    Axios.delete(`https://server.webwisdom.work/
api/deletelogfiles/${logid}`, )
  }
  const signOut = () => {
    setIsSignIn(false);
    setSignEmail(" ");
    setToken(" ");
    setTempPass(" ");
    Axios.get("https://server.webwisdom.work/signout", (err,result) => {
    console.log(err);
    })
    return <Navigate to="../" />;
  }
  // // Empty Verification
  // if (signEmail.length === 0) {
  //   return <Navigate to="../sign" />;
  // }
  // if (token.length === 0) {
  //   return <Navigate to="../sign" />;
  // }
  // if (tempPass.length === 0) {
  //   return <Navigate to="../sign" />;
  // }
  if (isSignIn) {
    console.log("SignIn");
  } else {
    localStorage.removeItem("signInEmail");
    localStorage.removeItem("temporaryPassword");
    localStorage.removeItem("token");
    return <Navigate to="../sign" />;
  }

  return (
    <main className="Dashboard">
      <section className="tabHead">
        <h2>WisdomMarks</h2>
        <q>Add your favorite webapps to your wisdommark</q>
        <button onClick={signOut} className="weblink">Sign Out🔒</button>
      </section>
      <section className="tabGrid">
        {toolList.map((value) => {
          return(
            <div className="tabCard" key={value.toolid}>
            <iframe  src={`https://www.youtube.com/embed/${value.videolink}`} title="YouTube video player"  allowFullScreen></iframe>
            <div className="tabPlat">
              <div>
              <img src={value.imagelink} alt={value.name} />
              <h3 >{value.name}</h3>
              </div>
              <div>
              <a href={value.tutoriallink} className="weblink" title={`Learn ${value.name}`}> 🎓 </a>
              <a href={value.weblink} target="_blank" rel="noopener noreferrer" className="weblink"> 🔗 </a>
              <button className="weblink" onClick={() => {
                removeTabMark(value.logid);
              }} > ❌ </button>
              </div>
            </div>
          </div>
          )
        })}
      </section>
    </main>
  );
}

export default Dashboard;
