import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import { isSignInContext, tempPassContext, tokenContext, signEmailContext } from "./Context";
import { Navigate } from "react-router-dom";



function Sign() {

// UNIVERSAL STATES
const {isSignIn, setIsSignIn} = useContext(isSignInContext);
const { setSignEmail} = useContext(signEmailContext);
const { setToken} = useContext(tokenContext);
const { setTempPass} = useContext(tempPassContext);

useEffect(() => {
  Axios.get("https://server.webwisdom.work/issignin").then((res) => {
    setIsSignIn(res.data.isSignIn);
      setSignEmail(res.data.mail);
      setToken(res.data.token);
      setTempPass(res.data.tempPassword);
      setMessageSignIn(res.data.message);
  });
  // eslint-disable-next-line
}, []);

  // FORM DATA STORAGE
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [messageSignUp, setMessageSignUp] = useState("");
  const [messageSignIn, setMessageSignIn] = useState("");

  const signUp = (e) => {
    setMessageSignIn("");
    setMessageSignUp("");
    Axios.post("https://server.webwisdom.work/signup", {
      signUpEmail: signUpEmail,
    }).then((res) => {
      setMessageSignUp(res.data.message);
      console.log(messageSignUp);
    }).catch((err) => {
      console.log(err);
    })
    e.preventDefault();
  }

  const signIn = (e) => {
    setMessageSignIn("");
    setMessageSignUp("");
    Axios.post("https://server.webwisdom.work/signin", {
      signInEmail: signInEmail,
    }).then((res) => {
      setMessageSignIn(res.data.message);
    }).catch((err) => {
      console.log(err);
    })
    e.preventDefault();
  }

  if(isSignIn){
          return <Navigate to="../dashboard" />;
        }
  return (
    <main className="Sign">
        <section className="SignUp">
          <h2>Sign Up</h2>
          <form action="../sign" method="post">
            <input 
            type="email"
            placeholder="Email@email.com"
            required
            onChange={(e) => {
              setSignUpEmail(e.target.value);
            }}
            />
            <button className="btn1" type="submit" onClick={signUp}>Sign Up</button>
          </form>
         <p className="alertMessage">{messageSignUp}</p> 
        </section>
            <hr />
        <section className="SignIn">
          <h2>Sign In</h2>
          <form action="../sign" method="post">
            <input 
            type="email"
            placeholder="Email@email.com"
            required
            onChange={(e) => {
              setSignInEmail(e.target.value);
            }}
            />
            <button className="btn2" type="submit" onClick={signIn}>Sign In</button>
          </form>
         <p className="alertMessage2">{messageSignIn}</p> 

        </section>
    </main>
  )
}

export default Sign;