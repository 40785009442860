import React, { useState } from "react";
import Axios from "axios";

function Contact() {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  const contactForm = () => {
    Axios.post("https://server.webwisdom.work/contactform", {
        contactName:contactName,
        contactEmail:contactEmail,
        contactMessage:contactMessage
    }).then(() => {
      alert("Successfully Inserted");
    })
  }
  return (
    <section>
      <div className="form">
        <input type="text" placeholder="Your Name 🧞"
        onChange={(e) => {
            setContactName(e.target.value);
        }}
        />
        <input type="email" placeholder="Your email 📭"
        onChange={(e) => {
            setContactEmail(e.target.value);
        }}
        />
        <textarea value={``} placeholder="Place your message" 
        onChange={(e) => {
            setContactMessage(e.target.value);
        }}
        >Place your message</textarea>
        <p> 🛑 We do not store any of your persoanl data on our site. Email and name is optional and to notify you, if your Tool is added to the site or not for given reason. </p>
        <p> 🛑 By submiting this form you agree to acknowlegde and accept our all privacy, disclaimers , terms & conditions. </p>
        <button className="btn1" type="submit" onClick={contactForm}>
          Request🍺
        </button>
      </div>
    </section>
  );
}

export default Contact;
