//  IMPORT COMPONENTS
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

// PAGES
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Tab from "./Tab";
import Blog from "./Blog";
import About from "./About";
import Sign from "./Sign";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
import FAQ from "./pages/FAQ";
import Dashboard from "./Dashboard";
import CategoryProfile from "./CategoryProfile";
import BlogProfile from "./BlogProfile";
import Error404 from "./Error404";

// CSS IMPORTS ....
import "./App.css";

//   ASSETS
import { isSignInContext, tempPassContext, tokenContext, signEmailContext } from "./Context";
import ScrollToTop from "./ScrollToTop";


function App() {
  const [isSignIn, setIsSignIn] = useState(false);
  const [signEmail, setSignEmail] = useState("");
  const [token, setToken] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [darkTheme, setDarkTheme] = useState(true);


  if(darkTheme){
    localStorage.setItem("theme",true);
  }else{
    localStorage.setItem("theme", false);
  }

  return (
    <div className={`App ${darkTheme?"dark":"light"}`}>
       <Helmet>
        <title>Web Wisdom Work</title>
        <meta name="description" content="WebWisdom.work is best des-dev web tool list resource that can help you to plan 🤔, structure 💠, get assets 📼, make assets, help in design, front-end des-dev 🗺 , back-end dev, server structure, in deployment and many more." />
        <meta name="keywords" content="Web Tool List, Web wisdom, WebWisdom, webwisdom, .work" />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta property="og:image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:description" content="WebWisdom.work is best web tool list resource for graphic & web designer and developer. | Web Tool List, webwisdom, .org" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta name="twitter:description" content="WebWisdom.work is a web tool library and tutorial site for graphic & web designer and developer" />
        <meta name="twitter:image" content="https://webwisdom.work/default.jpg" />
      </Helmet>
      <isSignInContext.Provider value={{ isSignIn, setIsSignIn }}>
        <tempPassContext.Provider value={{ tempPass, setTempPass }}>
          <tokenContext.Provider value={{ token, setToken }}>
            <signEmailContext.Provider value={{ signEmail, setSignEmail }}>
              <ScrollToTop />
              <Header />
              <aside className="wrapper" >
                <input type="checkbox" id="hide-checkbox" onClick={() => {
                  setDarkTheme(!darkTheme);
              }} />
                <label htmlFor="hide-checkbox" className="toggle">
                  <span className="toggle-button">
                    <span className="crater crater-1"></span>
                    <span className="crater crater-2"></span>
                    <span className="crater crater-3"></span>
                    <span className="crater crater-4"></span>
                    <span className="crater crater-5"></span>
                    <span className="crater crater-6"></span>
                    <span className="crater crater-7"></span>
                  </span>
                  <span className="star star-1"></span>
                  <span className="star star-2"></span>
                  <span className="star star-3"></span>
                  <span className="star star-4"></span>
                  <span className="star star-5"></span>
                  <span className="star star-6"></span>
                  <span className="star star-7"></span>
                  <span className="star star-8"></span>
                </label>
              </aside>
              <Routes>
              <Route path="/" element={<Tab />} />
              <Route path="*" element={<Error404 />} />
                <Route path="/home" element={<Home />} />
                <Route path="/tab" element={<Tab />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/about" element={<About />} />
                <Route path="/sign" element={<Sign />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/pages/terms" element={<Terms />} />
                <Route path="/pages/disclaimer" element={<Disclaimer />} />
                <Route path="/pages/faq" element={<FAQ />} />
                <Route path="/pages/privacy" element={<Privacy />} />
                <Route path="/categoryname/:categoryName" element={<CategoryProfile />} />
                <Route path="/blogprofile/:blogid" element={<BlogProfile />}></Route>
              </Routes>
              <Footer />
            </signEmailContext.Provider>
          </tokenContext.Provider>
        </tempPassContext.Provider>
      </isSignInContext.Provider>
    </div>
  );
}

export default App;
