import React from "react";
import Request from "./Request";
import Contact from "./Contact";
import { Helmet } from "react-helmet";

function About() {
  return (
    <main className="aboutUs">
      <Helmet>
        <title>AboutUs</title>
        <meta
          name="description"
          content="Learn about this website and creator in detail. About author of the website. Who made this website"
        />
        <meta
          name="keywords"
          content="who made web wisdom work, who made this site, about us, who made webwisdom"
        />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta
          property="og:image"
          content="https://webwisdom.work/default.jpg"
        />
        <meta
          property="og:description"
          content="Learn about this website and creator in detail. About author of the website. Who made this website"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta
          name="twitter:description"
          content="Learn about this website and creator in detail. About author of the website. Who made this website"
        />
        <meta
          name="twitter:image"
          content="https://webwisdom.work/default.jpg"
        />
      </Helmet>
      <h2>About Us</h2>
      <q>Contact form is below!</q>
      <section>
        <h3>About Web Wisdom 🧙</h3>
        <p>
          WebWisdom.work is a web des-dev tool ⚙ list site that can help you to
          planning 🤔, structuring 💠, getting assets 📼, making assets, help in
          design, front-end developement 🗺 , back-end developement, server
          structuring, in deployment and many more stuff!.
        </p>
        <p>
          you can search 🔍 for particular website, ranking, name, website type,
          also description(Yes, i am nerd!) & tags 🏷 for each case.
        </p>
        <p>
          You can change theme 🗿 of the site on your bottom right handside
          there is a rainbow circle. click on it and select your favorite color
          🟢 in theme and it will save automatically for your next return!
        </p>
        <p>
          Please bookmark 📌 this website and share with all your nerd friends,
          brothers & sisters, teamates, teachers, professors, bosses & clients
          💼
        </p>
      </section>
      <Request />

      <section>
        <h3>About ME! 👽</h3>
        <p>
          Hi, I am a DMIT NAIT student 🦉 , I was looking for tools ⚙ that can
          help me in my planning 🤔, structuring 💠, getting assets 📼, making
          assets, help in design, front-end developement 🗺 , back-end
          developement, server structuring, in deployemnt and many more stuff!
          There was some websites that exists 🐦 but not focused on web
          developement.
        </p>
        <p>
          Every year there can be new web tools that can help you to do every
          single step of making web tools⚙ itself. There are some cheap tools
          and premium tools but my main focus is on free tools. Free 🆓 is
          keywork to rule web yes people love free or community supportted web
          tool and love open source.
        </p>
        <p>
          Hope you like my list and if i am missing your favorite 😍 tools
          please send me the link or name of the tool that you would like to
          share with everyone! Thank you so much for being generous in every
          case.
        </p>
      </section>
      <section>
        <h3>Feeling generous! 🥳 Help me ou</h3>
        <p>
          Share this list with all your nerd friends 🧑 , teacher, professors,
          bosses 🧞 & clients Post this site on social media
        </p>
      </section>

      <Contact />
    </main>
  );
}

export default About;
