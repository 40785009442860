import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { isSignInContext, tokenContext, signEmailContext } from "./Context";
import { Helmet } from "react-helmet";

function Tutorial() {
  // UNIVERSAL STATES
  const { isSignIn } = useContext(isSignInContext);
  const { signEmail } = useContext(signEmailContext);
  const { token } = useContext(tokenContext);
  const [tabList, setTabList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // if (signEmail.length === 0) {
  //   setSignEmail(localStorage.getItem("Email"));
  //   setToken(localStorage.getItem("token"));
  // }

  useEffect(() => {
    Axios.get(`https://server.webwisdom.work/
api/gettool`).then((res) => {
      setTabList(res.data);
    });
  }, []);

  const searchQuery = (e) => {
    if (searchTerm !== "" || searchTerm !== " ") {
      Axios.get(`https://server.webwisdom.work/
api/search/${searchTerm}`).then((res) => {
        setTabList(res.data);
      });
    }
    e.preventDefault();
  };
  const searchReset = (e) => {
    console.log("Reset");
    Axios.get(`https://server.webwisdom.work/
api/gettool`).then((res) => {
      setTabList(res.data);
    });
    e.preventDefault();
  };

  const addTabMark = (tabCode) => {
    if (isSignIn) {
      console.log("If triggered");
      Axios.post(`https://server.webwisdom.work/
api/addtab`, {
        signEmail: signEmail,
        tabCode: tabCode,
        token: token,
      }).then((err, res) => {
        console.log(res);
      });
    } else {
      console.log("else Triggered");
      <Navigate to="../sign" />;
    }
  };

  return (
    <main className="Tab">

<Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Learn🎓 your favorite tool, 📌Pin the tool that you like & 🔗Open tool link."
        />
        <meta
          name="keywords"
          content="web wisdom work, webwisdom.work, web tools, web apps"
        />
        <meta name="author" content="Prabhjot Kumar" />
        <link rel="shortcut icon" type="image/x-icon" href="./favicon.ico" />
        <link rel="apple-touch-icon" type="image/x-icon" href="./favicon.png" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="360" />
        <meta name="image" content="https://webwisdom.work/default.jpg" />
        <meta property="og:title" content="webwisdom.work" />
        <meta property="og:url" content="https://webwisdom.work/" />
        <meta
          property="og:image"
          content="https://webwisdom.work/default.jpg"
        />
        <meta
          property="og:description"
          content="Learn🎓 your favorite tool, 📌Pin the tool that you like & 🔗Open tool link."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="webwisdom.work" />
        <meta
          name="twitter:description"
          content="Learn🎓 your favorite tool, 📌Pin the tool that you like & 🔗Open tool link."
        />
        <meta
          name="twitter:image"
          content="https://webwisdom.work/default.jpg"
        />
      </Helmet>

      <h2>Explore Tools 🕵️‍♀️</h2>
      <p> Learn🎓 your favorite tool, 📌Pin the tool that you like & 🔗Open tool link. </p>
      <Link to="../sign" className="btn3">
        Sign Up
      </Link>
      <form action="../tutorial" className="searchBar">
        <input
          type="text"
          placeholder="search"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <button type="submit" onClick={searchQuery}>
          🔍
        </button>
        <button type="reset" onClick={searchReset}>
          ⛔
        </button>
      </form>
      <section className="tabGrid">
        {tabList.map((value) => {
          return (
            <div className="tabCard" key={value.toolid}>
              <iframe src={`https://www.youtube.com/embed/${value.videolink}`} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
              <div className="tabPlat">
                <div>
                  <img src={value.imagelink} alt={value.name} />
                  <h3>{value.name}</h3>
                </div>
                <div>
                  <a className="weblink" href={`../../blogprofile/${value.tutoriallink}`} title={`Learn ${value.name}`}>
                    🎓
                  </a>
                  <a href={value.weblink} target="_blank" rel="noopener noreferrer" className="weblink">
                    🔗
                  </a>
                  <button onClick={ () => addTabMark(value.toolid)}> 📌 </button>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </main>
  );
}

export default Tutorial;
